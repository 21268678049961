// @ts-ignore
import yaml from "../../data/how-to-be-insightful.yaml"
import React from "react"
import { validatePageYamlData } from "../../helpers/prop-types"
import Header from "../../components/sections/header"
import LayoutContainer from "../../components/layout/layout-container"
import { SectionBox } from "../../components/sections/section-box"
import TitleWithUnderline from "../../components/unitary/title-with-underline"
import Columns from "../../components/layout/columns"
import {
  Portrait,
  PORTRAIT_BODY_TEXT_ALIGNMNENTS,
} from "../../components/child-mappable/portrait"
import { graphql } from "gatsby"
import { generateImage } from "../../components/factories/image-factory"
import Column from "../../components/layout/column"
import H2 from "../../components/unitary/h2"
import Content from "../../components/unitary/content"
import Markdown from "../../components/unitary/markdown"
import { PortraitArrayWithTitle } from "../../components/sections/portrait-array-with-title"
import { TwoByTwo } from "../../components/portrait-children/two-by-two"
import Landscape from "../../components/h2nits/landscape"
import { Infobox } from "../../components/sections/infobox"

export default function HowToBeInsightful(props) {
  validatePageYamlData(yaml)

  const images = props.data

  const teslaImageComponent = generateImage(
    images,
    yaml.insights_live_in_context.children[1].image
  )

  return (
    <LayoutContainer>
      <Header {...yaml.header} />
      <Infobox body={yaml.introduction.body} />
      <SectionBox>
        <TitleWithUnderline title={yaml.insights_live_in_context.title} />
        <Columns>
          <Portrait
            data={yaml.insights_live_in_context.children[0]}
            options={{
              portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
            }}
          />
          {/* This is like a portrait component with the image to the right of the text.  Could be extracted */}
          <Column classes={["has-text-centered"]}>
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <H2 title={yaml.insights_live_in_context.children[1].title} />
            </div>
            <Columns>
              <Column>
                <div className="has-text-left">
                  <Content
                    body={yaml.insights_live_in_context.children[1].body}
                  />
                </div>
              </Column>
              <Column classes={["is-5", "px-5"]}>
                <div className="py-1 px-2">{teslaImageComponent}</div>
                <Markdown
                  input={
                    yaml.insights_live_in_context.children[1].image.caption
                  }
                />
              </Column>
            </Columns>
          </Column>
        </Columns>
        <Columns>
          <Portrait
            data={yaml.insights_live_in_context.children[2]}
            options={{
              portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
            }}
          />
          <Portrait
            data={yaml.insights_live_in_context.children[3]}
            options={{
              portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
            }}
          />
        </Columns>
      </SectionBox>
      <PortraitArrayWithTitle
        data={yaml.obvious_in_hindsight}
        images={images}
        options={{
          portraitBodyTextAlignment: PORTRAIT_BODY_TEXT_ALIGNMNENTS.left,
        }}
      />
      <SectionBox>
        <TitleWithUnderline title={yaml.improve_your_chances.title} />
        <Columns classes={["is-centered"]}>
          <Column classes={["has-text-centered", "is-10"]}>
            <Content
              body={yaml.improve_your_chances.subtitle}
              classes={["p-5"]}
            />
          </Column>
        </Columns>
        <TwoByTwo data={yaml.improve_your_chances} />
      </SectionBox>
      <SectionBox>
        <Landscape
          data={yaml.learn_more}
          titleStyle="chevron"
          titleClasses={["is-3"]}
        />
      </SectionBox>
    </LayoutContainer>
  )
}

export const query = graphql`
  query {
    tesla_supercharger: file(
      relativePath: { eq: "how-to-be-insightful/tesla_supercharger.png" }
    ) {
      ...fixedHeight100
    }
    thinking_fast_and_slow: file(
      relativePath: { eq: "how-to-be-insightful/thinking_fast_and_slow.png" }
    ) {
      ...fixedWidth90
    }
    the_lean_startup: file(
      relativePath: { eq: "how-to-be-insightful/the_lean_startup.png" }
    ) {
      ...fixedWidth90
    }
  }
`
