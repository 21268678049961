import React from "react"
import { SectionBox } from "../../components/sections/section-box"
import { FaInfoCircle } from "react-icons/fa"
import Columns from "../../components/layout/columns"

export function Infobox(props) {
  return (
    <SectionBox>
      <Columns>
        <div className="column is-flex is-justify-content-center">
          <span className="icon is-large is-size-3 mx-3">
            <FaInfoCircle />
          </span>
          <span className="is-flex is-align-items-center">{props.body}</span>
        </div>
      </Columns>
    </SectionBox>
  )
}
